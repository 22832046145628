/** @jsxImportSource @emotion/react */
import {
  AddOutlined,
  RemoveOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import ButtonSquared from "../../../ButtonSquared/View";
import FrameDialog from "../../../FrameDialog/View";
import { OperatorActions } from "../../Model";
import OperatorActionDisplay from "../OperatorActionDisplay/View";
import { useDocumentViewerPresenter } from "./Presenter";
import { useDocumentViewerStyle } from "./Style";

function DocumentViewer({
  devolveBlock,
  documents,
  showReportDialogRef,
  operatorActions,
  hiddenList = false,
  emptyMessage = "Não há blocos disponíveis para serem categorizados",
  minor = false,
  isBusy = false,
}: {
  devolveBlock?: () => void;
  documents?: string[];
  showReportDialogRef?: React.MutableRefObject<() => void>;
  hiddenList?: boolean;
  operatorActions?: OperatorActions;
  emptyMessage?: string;
  minor?: boolean;
  isBusy?: boolean;
}) {
  const style = useDocumentViewerStyle();

  const presenter = useDocumentViewerPresenter(documents);

  return (
    <div css={style.viewer(hiddenList, minor)}>
      {/* Page Buttons */}
      {documents !== undefined && !hiddenList ? (
        <div css={style.viewLeft(documents?.length ?? 0)}>
          {documents.map((currentDocument, index) => (
            <div
              key={`document-${index}`}
              css={[
                style.document(currentDocument),
                index === presenter.documentSelected
                  ? style.documentSelected
                  : undefined,
              ]}
              onClick={() => presenter.setDocumentSelected(index)}
            />
          ))}
        </div>
      ) : undefined}

      {/* Image View */}
      {documents !== undefined ? (
        <div
          ref={presenter.viewRef}
          css={style.container(hiddenList, minor)}
          onWheel={presenter.handleWheel}
          onMouseDown={presenter.handleDragDocument}
          onMouseMove={presenter.handleDragDocument}
          onMouseUp={presenter.handleDragDocument}
          onMouseLeave={presenter.handleDragDocument}
        >
          <div
            css={style.relativeContainer(
              presenter.documentScale,
              presenter.pos.x,
              presenter.pos.y,
              presenter.allowDragging,
              presenter.isDragging
            )}
          >
            <img
              id={String(presenter.documentSelected)}
              ref={presenter.documentRef}
              src={documents[presenter.documentSelected]}
              alt={String(presenter.documentSelected)}
              css={style.moveDocument}
              onLoad={() => presenter.handleZoom(false)}
            />
          </div>
        </div>
      ) : (
        <div css={style.containerWihtoutShrink}>{emptyMessage}</div>
      )}

      {/* Control Buttons */}
      {documents !== undefined && documents.length > 0 ? (
        <div css={style.viewRight}>
          <div css={style.groupButtons}>
            <div css={style.separator} />
            {showReportDialogRef ? (
              <>
                <ButtonSquared
                  color="warning"
                  onClick={() => showReportDialogRef.current!()}
                >
                  <WarningOutlined />
                </ButtonSquared>
                <div css={style.separator} />
                <div css={style.separator} />
              </>
            ) : undefined}
            {devolveBlock ? (
              <ButtonSquared
                disabled={isBusy}
                onClick={() => presenter.setDevolveDialog(true)}
                color="secondary"
              >
                <span className="material-symbols-outlined">
                  drive_file_move_rtl
                </span>
              </ButtonSquared>
            ) : undefined}
          </div>

          <div css={style.groupButtons}>
            <ButtonSquared
              color="secondary"
              onClick={() => presenter.handleZoom(true)}
            >
              <AddOutlined />
            </ButtonSquared>
            <div css={style.separator} />
            <ButtonSquared
              color="secondary"
              onClick={() => presenter.handleZoom(false)}
            >
              <RemoveOutlined />
            </ButtonSquared>
          </div>

          <div css={style.groupButtons}>
            {operatorActions?.contabilizedBy ? (
              <OperatorActionDisplay
                action="Contabilizado"
                by={operatorActions.contabilizedBy}
              />
            ) : (
              <div css={style.placeholder} />
            )}
            <div css={style.separator} />
            {operatorActions?.categorizedBy ? (
              <OperatorActionDisplay
                action={
                  "Categorizado" +
                  (operatorActions.alsoContabilize ? " e contabilizado" : "")
                }
                by={operatorActions.categorizedBy}
              />
            ) : (
              <div css={style.placeholder} />
            )}
          </div>
        </div>
      ) : undefined}
      <FrameDialog
        open={presenter.devolveDialog}
        onClose={() => presenter.setDevolveDialog(false)}
        title="Atenção"
        onConfirm={devolveBlock}
        content={
          <>
            <div css={style.emphasis}>Esta ação é permanente.</div>
            <div css={style.margins}>
              Blocos devolvidos não ficam mais disponíveis para categorização.
              Recomendado quando o bloco não for usado na contabilidade.
            </div>
            <div>Deseja prosseguir?</div>
          </>
        }
      />
    </div>
  );
}

export default DocumentViewer;
